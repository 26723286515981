import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: AppTheme) {
  return appCreateStyles({
    root: {},
    text: {
      margin: '5px 0 25px',
      padding: '0 20px',
      fontWeight: 300,
    },
    content: {
      [breakpoints.down(Layout.mobile)]: {
        padding: '0',
      },
    },
    buttonsContainer: {
      [breakpoints.up(Layout.tablet)]: {
        padding: '0 20px 20px',
      },
    },
    button: {
      flexGrow: 1,
      flexBasis: 1,
      [breakpoints.down(Layout.mobile)]: {},
    },
  });
}
