import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    container: {
      marginTop: spacing(3),
      height: 225,
    },
  });
}
