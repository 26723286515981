import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from '@shared/components/button';

import { styles } from './block-user-error-dialog.styles';

export interface BlockUserErrorDialogProps extends AppWithStyles<typeof styles> {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

const BlockUserErrorDialogComponent: React.FC<BlockUserErrorDialogProps> = ({
  classes,
  message,
  isOpen,
  onClose,
}) => {
  const separatedMessages = message.split('/n');
  return (
    <Dialog open={isOpen} onClose={onClose} className={classes.root}>
      <DialogContent className={classes.content}>
        <div className={classes.text}>
          {separatedMessages.length ? (
            separatedMessages.map((messageItem: string, index) => {
              return <p key={index + 2000}>{messageItem}</p>;
            })
          ) : (
            <div></div>
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonsContainer}>
        <Button
          key={1001}
          onClick={onClose}
          className={classes.button}
          variant={'text'}
          color={'primary'}
          disabled={false}
        >
          {t`Close`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const BlockUserErrorDialog = appWithStyles(styles)(BlockUserErrorDialogComponent);
