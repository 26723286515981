import { CSSProperties, FC } from 'react';

import { Breadcrumbs } from '@mui/material';
import { Link } from '@shared/components/link';

export interface BreadcrumbsProps {
  links: { label: string; path: string; disabled?: boolean }[];
}

export const AppBreadcrumbs: FC<BreadcrumbsProps> = ({ links }) => {
  const styles = {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 600,
    pointerEvents: 'none',
  } as CSSProperties;

  return (
    <Breadcrumbs separator="-" aria-label="breadcrumb">
      {links.map(({ label, path, disabled }, index) => {
        const isLast = index === links.length - 1;
        return (
          <Link
            style={disabled ? styles : undefined}
            to={path}
            key={index}
            underline={isLast ? 'always' : 'hover'}
          >
            {label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
