import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { Backdrop, BackdropProps, CircularProgress } from '@mui/material';

import { styles } from './backdrop-loader.styles';

type BackdropLoaderProps = AppWithStyles<any> & BackdropProps;

const BackdropLoaderComponent: React.FC<BackdropLoaderProps> = (props) => {
  const { classes, ...rest } = props;

  return (
    <Backdrop className={classes.backdrop} {...rest}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const BackdropLoader = appWithStyles(styles)(BackdropLoaderComponent);
