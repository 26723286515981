import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { styles } from './line-chart.styles';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props extends AppWithStyles<typeof styles> {
  options: ChartOptions<any>;
  data: ChartData<any>;
}

const LineChartComponent: React.FC<Props> = ({ classes: c, options, data }) => {
  return (
    <div className={`${c.container} line-chart-view`}>
      <Line options={options} data={data} />
    </div>
  );
};

export const LineChart = appWithStyles(styles)(LineChartComponent);
