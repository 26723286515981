import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from '@shared/components/button';

import { styles } from './confirmation-dialog.styles';

export interface ConfirmationDialogButton {
  label: string;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  disabled?: ButtonProps['disabled'];
  onClick: () => void;
}

export interface ConfirmationDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  buttons: ConfirmationDialogButton[];
  title: string;
  content?: string;
  children?: React.ReactNode;
}

const ConfirmationDialogComponent: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  classes,
  onClose,
  buttons,
  title,
  content,
  children,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} scroll="paper" maxWidth="md" className={classes.root}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent className={classes.content}>
        {content && <DialogContentText className={classes.text}>{content}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions className={classes.buttonsContainer}>
        {buttons.map((b, i) => {
          const { label, variant, color, disabled, onClick } = b;
          return (
            <Button
              key={i}
              onClick={onClick}
              className={classes.button}
              variant={variant || 'outlined'}
              color={color || 'secondary'}
              disabled={disabled}
            >
              {label}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmationDialog = appWithStyles(styles)(ConfirmationDialogComponent);
