import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles({ spacing }: AppTheme) {
  return appCreateStyles({
    option: {
      fontSize: 12,
    },
    buttonRoot: {
      '&.MuiButton-text': {
        color: _var(CSS_VARIABLES.accentColor),
        padding: spacing(0, 6, 0, 0),
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:hover > span': {
          color: _var(CSS_VARIABLES.accentColor),
        },
      },
    },
    endIcon: {
      marginLeft: spacing(2),
    },
  });
}
