import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import MaterialLink, { LinkProps as MaterialLinkProps } from '@mui/material/Link';
import cx from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { styles } from './link.styles';

type CoreNavLinkProps = Pick<NavLinkProps, 'replace' | 'to'>;

type CoreMaterialLinkProps = Omit<MaterialLinkProps, 'classes' | 'component'>;

export interface LinkProps
  extends AppWithStyles<typeof styles>,
    CoreNavLinkProps,
    CoreMaterialLinkProps {}

const LinkComponent: React.FC<LinkProps> = ({ classes, className, children, ...otherProps }) => {
  return (
    <MaterialLink component={NavLink} className={cx(classes.root, className)} {...otherProps}>
      {children}
    </MaterialLink>
  );
};

export const Link = appWithStyles(styles)(LinkComponent);
