import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { MenuItem, Menu } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

import { Button } from '../button';

import { styles } from './dropdown.styles';

export type DropdownOption = { id: string | number; label: string };

export interface Props extends AppWithStyles<typeof styles> {
  title: string;
  options: DropdownOption[];
  startIcon?: ButtonProps['startIcon'];
  endIcon?: ButtonProps['endIcon'];
  disabled?: ButtonProps['disabled'];
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  onClick: (value: string) => void;
  className?: string;
}

const DropdownComponent: React.FC<Props> = ({
  classes,
  title,
  options,
  startIcon,
  endIcon,
  onClick,
  disabled,
  variant,
  color,
  className,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleOnChange = (id: DropdownOption['id']) => () => {
    onClick(id as string);
    handleClose();
  };

  return (
    <>
      <Button
        className={className}
        classes={{ root: classes.buttonRoot, endIcon: classes.endIcon }}
        variant={variant}
        color={color}
        disabled={disabled}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {title}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem key={option.id} className={classes.option} onClick={handleOnChange(option.id)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const AppDropdown = appWithStyles(styles)(DropdownComponent);
