import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';

export const styles = (theme: AppTheme) =>
  appCreateStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#464660',
    },
  });
