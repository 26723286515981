import { ElementType, FC, ReactNode, useMemo } from 'react';

import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export interface ButtonProps extends MuiButtonProps {
  text?: ReactNode;
  loading?: boolean;
  component?: ElementType;
  componentProps?: { [key: string]: unknown };
  children?: React.ReactNode;
}

const ButtonComponent: FC<ButtonProps> = ({
  text,
  loading,
  children,
  component: Component,
  componentProps,
  color = 'primary',
  ...otherProps
}) => {
  const baseProps: MuiButtonProps = {
    color,
    variant: 'outlined',
    disabled: loading,
    ...otherProps,
  };

  const spinner = useMemo(
    () => (loading ? <CircularProgress size={15} style={{ marginLeft: 8 }} /> : null),
    [loading],
  );

  const content = (
    <MuiButton {...baseProps}>
      {text || children} {spinner}
    </MuiButton>
  );

  if (Component) {
    return (
      <Component {...componentProps} {...{ style: { textDecoration: 'none' } }}>
        {content}
      </Component>
    );
  }

  return content;
};

export const Button = ButtonComponent;
